.create-planogram-modal {
    height: 100%;
    & > .radio-group-container {
        & > * {
            
            border: none !important;

            & > * {
                display: flex;
                flex-direction: column !important;
                padding-left: 0 !important;
        
                & > *:not(:last-child) {
                    margin-bottom: 32px;
                }
            }
        }
    }
}