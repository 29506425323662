// Colors
$scroll-thumb: #a2a2b3;
$scroll-track: #d9e0e6;

$hover-grey: #F7F9FB;
$light-grey: #EEF0F8;

$dark-grey-text: #1B283F;

html {
    scroll-behavior: smooth;
}

.full-screen-modal>.modal-dialog {
    max-width: 93vw !important;
    max-height: 93vw !important;
    margin: 1.75rem auto !important;
}

//Theme overrides 
#kt_footer {
    z-index: 0;
}

#kt_body .label.label-dot {
    line-height: 8px;
    min-height: 8px;
    min-width: 8px;
    height: 8px;
    width: 8px;
}

.modal-backdrop:nth-of-type(2n) {
    z-index: 1050 !important;
}

.extended-table-hover thead tr:hover {
    cursor: auto !important;
    background: none !important;
}

.new-row {
    background: #F1F8FF;
}

.btn .svg-icon {
    margin-right: 0;
}

.text-muted.form-control:disabled {
    color: #545d70 !important;
}

.label.label-light-secondary {
    color: #545d70 !important;
}

// Border
.border-solid {
    border-style: solid;
}

.border-width-1 {
    border-width: 1px !important;
}

.border-width-2 {
    border-width: 2px !important;
}

.border-width-3 {
    border-width: 3px !important;
}

.border-radius-md {
    border-radius: 6px !important;
}

.border-radius-lg {
    border-radius: 9px !important;
}

.background-primary {
    background: #E1F0FF;
}

.background-warning {
    background: #FFF4DE;
}

.outline-none {
    outline: none;
}

.disabled,
*:disabled {
    cursor: url('../icons/Not-Allowed.svg'), not-allowed !important;
}

body .nav.nav-tabs.grey-nav-pills {
    border: none;

    .nav-link {
        font-weight: 500;
        margin: 0;
        color: $primary;
        border: #E5EAEE;

        &.active,
        &:hover {
            background-color: $light-grey;
        }
    }
}

.active-select {
    background-color: #F3F6F9;
}

// Font effects
.line-through {
    text-decoration: line-through !important;
}

.underlined,
.underlined:hover {
    text-decoration: underline !important;
}
.overflow-y-hidden {
    overflow-y: hidden !important;
}
.overflow-y-auto {
    overflow-y: auto !important;
}

.overflow-x-auto {
    overflow-x: auto !important;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.overflow-visible {
    overflow: visible !important
}

.overflow-auto {
    overflow: auto !important;
}

// Cursor
.not-allowed {
    cursor: url('../icons/Not-Allowed.svg'), not-allowed !important;
}

.pointer {
    cursor: pointer !important;
}

.cursor-default {
    cursor: default !important;
}

.cursor-grab {
    cursor: grab !important;
}

.cursor-grabbing {
    cursor: grabbing !important;
}

.cursor-inherit {
    cursor: inherit !important;
}

.pointer-events-none {
    pointer-events: none !important;
}

// Checkbox
.checkbox-inline.disabled {
    opacity: 0.5 !important;
}

// Hover
.hover-light:hover {
    background: $hover-grey !important;
    background-color: $hover-grey !important;
    transition: all 0.5s;
}

// Shadow
.element-shadow-light {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.025);
}

.element-shadow-mid {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
}

// Labels
form label {
    white-space: nowrap;
}

.square:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.image-card:hover {
    border: 2px solid #E5EAEE;
}


// react-datepicker overrides
#kt_body {
    .react-datepicker {

        padding: 0.65rem;
        font-family: $font-family-base;
        font-size: 0.9rem;
        border: 1px solid #E5EAEE;
        box-shadow: 0px 3px 10px #00000029;
        border-radius: 6px;
        width: max-content;

        .react-datepicker__navigation {
            top: 1.1rem;

        }

        .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
            right: 94px;
        }

        .react-datepicker__header {
            background-color: #ffffff;
            border-bottom: none;

            .react-datepicker__current-month,
            .react-datepicker-time__header {
                font-weight: 500;
                padding-bottom: 0.75rem;
                font-size: 1rem;
            }

            .react-datepicker__day-name {
                color: rgba(0, 0, 0, 0);
                text-transform: uppercase;

                &:first-letter {
                    color: #B5B5C3;
                }
            }
        }

        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
            height: 12rem !important;

            &::-webkit-scrollbar-track {
                background: rgba(0, 0, 0, 0);
            }

            /* For Mozilla */
            & {
                scrollbar-color: $scroll-thumb rgba(0, 0, 0, 0);
            }
        }
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__time li.react-datepicker__time-list-item--selected,
    .react-datepicker__close-icon::after {
        background-color: #3699FF;
    }

    .react-datepicker__time li.react-datepicker__time-list-item--selected {
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.content-normal-layout {
    transition: all .3s ease-in;
}

.move-content-down {
    // transform: translateY(40px);
    // -webkit-transform: translateY(100%); //if your div is 200px, this will move it down by 200px, if it is 100px it will down by 100px etc
    // transform: translateY(100%);         //if your div is 200px, this will move it down by 200px, if it is 100px it will down by 100px etc
    margin-top: 40px;
}


/* Scrollbar */

/* For Chrome, Edge, Safari  */
*::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

*::-webkit-scrollbar-thumb {
    background: $scroll-thumb;
    border-radius: 10px;
}

*::-webkit-scrollbar-track {
    background: $scroll-track;
}

/* For Mozilla */
* {
    scrollbar-width: thin;
    scrollbar-color: $scroll-thumb transparent;
    scrollbar-arrow-color: transparent;
}

::-webkit-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: $scroll-thumb $scroll-track;
}

// Divider

.vertical-divider {
    display: inline-block;
    height: 100%;
    // width: 1px;
    background: #e5eaee;
    border-left: 1px solid #e5eaee;
    margin: 0.35rem 0.5rem;

    @media (max-width: 992px) {
        display: none;
    }
}

// Custom Header
#sub-header {
    transition: all 0.3s ease;

    & > * {
        align-items: center;
    }
}

#header-overlay {
    visibility: hidden;
    pointer-events: none;
}

.aside-overlay {
    background: rgba(0, 0, 0, 0.2) !important;
}

@media (max-width: 992px) {
    #kt_body.topbar-mobile-on {
        .topbar {
            z-index: 96;
            box-shadow: none !important;
            border-bottom: 1px solid #eff0f6;
        }

        #header-overlay {
            visibility: visible;
            pointer-events: all;
            background: rgba(0, 0, 0, 0.2);
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            overflow: hidden;
            z-index: 95;
            animation: animation-offcanvas-fade-in .6s ease 1;
          }

        #sub-header {
            top: 49px;
        }
    }
}

.red-dot {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 5px;
    border: 1px solid white;
    right: 1px
}

.thumbnail {
    min-height: 56px;
    max-height: 56px;
    height: 56px;
    max-width: 56px;
}

//Temp TODO to be removed after all table search boxes are in the subheader
#sub-header .row {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;

    .input-group {
        margin: 0.5rem 0 !important
    }
}

// Bootstrap table overrides
.extended-table-hover {
    tr:hover {
        cursor: pointer;
        background: $hover-grey;
    }
}


// Crud modal
.crud-modal {
    .modal-content {
        min-height: 88vh;
        max-height: 88vh;
    }

    .modal-body {
        height: 70vh;
        // overflow: hidden;
        overflow-x: visible;
        overflow-y: auto;
        padding-bottom: 0 !important;
        
        & > div > div {
            height: 66vh;
            overflow-x: visible;
            // overflow-y: auto;

            @media (min-resolution: 1.25dppx) {
                height: 61vh;
            }

            & > form {
                height: 99% !important;
                overflow: visible;
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.modal-body-with-tabs {
    max-height: 65vh !important;
}

.image-tab-thumbnail-container {
    align-items: center;
    max-width: 100%;
    overflow-x: hidden !important;

    @media (min-width: 992px) {
        width: 10rem;
        min-width: 10rem;
        max-width: 10rem;
    }
}

.of-contain {
    object-fit: contain;
}

.fixed {
    position: fixed;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.zindex-99 {
    z-index: 99;
}

.grid {
    display: grid !important;
}

.flex {
    display: flex;
}

.inline-flex {
    display: inline-flex;
}

.flex-1 {
    flex: 1 !important;
}

.flex-center {
    align-items: center;
    justify-content: center;
}

.flex-row-revenrse {
    flex-direction: row-reverse;
}

.flex-row {
    flex-direction: row;
}
.flex-column {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.jc-flex-center {
    justify-content: center !important;
}

.jc-flex-start {
    justify-content: flex-start !important;
}

.jc-flex-end {
    justify-content: flex-end !important;
}

.space-between {
    justify-content: space-between !important;
}

.align-center {
    align-items: center !important;
}

.align-start {
    align-items: flex-start !important;
}

.align-end {
    align-items: flex-end !important;
}

.text-center {
    text-align: center;
}

.text-end {
    text-align: end;
}

.text-wrap {
    text-wrap: wrap;
}

// Opacity
.opacity-0 {
    opacity: 0 !important;
}

.opacity-5 {
    opacity: 0.05 !important;
}

.opacity-10 {
    opacity: 0.1 !important;
}

.opacity-15 {
    opacity: 0.15 !important;
}

.opacity-20 {
    opacity: 0.2 !important;
}

.opacity-25 {
    opacity: 0.25 !important;
}

.opacity-30 {
    opacity: 0.3 !important;
}

.opacity-35 {
    opacity: 0.35 !important;
}

.opacity-40 {
    opacity: 0.4 !important;
}

.opacity-45 {
    opacity: 0.45 !important;
}

.opacity-50 {
    opacity: 0.5 !important;
}

.opacity-55 {
    opacity: 0.55 !important;
}

.opacity-60 {
    opacity: 0.6 !important;
}

.opacity-65 {
    opacity: 0.65 !important;
}

.opacity-70 {
    opacity: 0.7 !important;
}

.opacity-75 {
    opacity: 0.75 !important;
}

.opacity-80 {
    opacity: 0.8 !important;
}

.opacity-85 {
    opacity: 0.85 !important;
}

.opacity-90 {
    opacity: 0.9 !important;
}

.opacity-95 {
    opacity: 0.95 !important;
}

.opacity-100 {
    opacity: 1 !important;
}

.break-word {
    word-wrap: break-word;
}

// Font weight
.fw-100 {
    font-weight: 100 !important;
}

.fw-200 {
    font-weight: 200 !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.text-nowrap {
    white-space: nowrap
}
.text-wrap-break-spaces {
    white-space: break-spaces
}

.text-ellipsis {
    text-overflow: ellipsis;
}

// Font size 
.fs-24 {
    font-size: 24px !important;
}

.fs-22 {
    font-size: 22px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-17 {
    font-size: 17px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-11 {
    font-size: 11px !important;
}

.fs-10 {
    font-size: 10px !important;
}

.fs-8 {
    font-size: 8px !important;
}

.lh-13 {
    line-height: 13px;
}

.lh-17 {
    line-height: 17px;
}

.lh-18 {
    line-height: 18px;
}
.lh-20 {
    line-height: 20px;
}
.lh-35 {
    line-height: 35px;
}

.uppercase {
    text-transform: uppercase;
}

// Icon size
.svg-icon-size-1 svg {
    height: 1rem !important;
    width: auto !important;
}

.svg-icon-size-2 svg {
    height: 1.25rem !important;
    width: auto !important;
}

.svg-icon-size-3 svg {
    height: 1.5rem !important;
    width: auto !important;
}

.svg-icon-size-4 svg {
    height: 1.75rem !important;
    width: auto !important;
}

.svg-icon-size-5 svg {
    height: 2rem !important;
    width: auto !important;
}

.svg-icon-size-6 svg {
    height: 2.25rem !important;
    width: auto !important;
}

.svg-icon-size-7 svg {
    height: 2.5rem !important;
    width: auto !important;
}

.svg-icon-size-8 svg {
    height: 2.75rem !important;
    width: auto !important;
}

.svg-icon-size-9 svg {
    height: 3rem !important;
    width: auto !important;
}

.svg-icon-size-10 svg {
    height: 3.25rem !important;
    width: auto !important;
}

.svg-icon-size-11 svg {
    height: 3.5rem !important;
    width: auto !important;
}

.svg-icon-size-12 svg {
    height: 3.75rem !important;
    width: auto !important;
}

.svg-icon-size-13 svg {
    height: 4rem !important;
    width: auto !important;
}

.svg-icon-size-14 svg {
    height: 4.25rem !important;
    width: auto !important;
}

.svg-icon-size-15 svg {
    height: 4.5rem !important;
    width: auto !important;
}

.svg-icon-orange {
    svg {
        path {
            fill: #FFA800 !important
        }
    }
}

.svg-red-element:hover {
    svg {
        #Element {
            fill: #F64E60 !important
        }
    }
}

.svg-icon-grey {
    svg {
        path {
            fill: #B5B5C3 !important
        }
    }
}

.svg-icon-light-grey {
    svg {
        path {
            fill: #E5EAEE !important
        }
    }
}

// Width
.w-5 {
    width: 5% !important;
}

.w-7 {
    width: 7% !important;
}

.w-8 {
    width: 8% !important;
}

.w-10 {
    width: 10% !important;
}

.w-15 {
    width: 15% !important;
}

.w-20 {
    width: 20% !important;
}

.w-25 {
    width: 25% !important;
}

.w-30 {
    width: 30% !important;
}

.w-35 {
    width: 35% !important;
}

.w-40 {
    width: 40% !important;
}

.w-45 {
    width: 45% !important;
}

.w-50 {
    width: 50% !important;
}

.w-55 {
    width: 55% !important;
}

.w-60 {
    width: 60% !important;
}

.w-65 {
    width: 65% !important;
}

.w-70 {
    width: 70% !important;
}

.w-75 {
    width: 75% !important;
}

.w-80 {
    width: 80% !important;
}

.w-85 {
    width: 85% !important;
}

.w-90 {
    width: 90% !important;
}

.w-95 {
    width: 95% !important;
}

.w-100 {
    width: 100% !important;
}

.wm-80 {
    max-width: 80% !important;
}

.wm-90 {
    max-width: 90% !important;
}

.wm-100 {
    max-width: 100% !important;
}

.w-auto {
    width: auto;
}

// Height
.h-5 {
    height: 5% !important;
}

.h-10 {
    height: 10% !important;
}

.h-15 {
    height: 15% !important;
}

.h-20 {
    height: 20% !important;
}

.h-25 {
    height: 25% !important;
}

.h-30 {
    height: 30% !important;
}

.h-35 {
    height: 35% !important;
}

.h-40 {
    height: 40% !important;
}

.h-45 {
    height: 45% !important;
}

.h-50 {
    height: 50% !important;
}

.h-55 {
    height: 55% !important;
}

.h-60 {
    height: 60% !important;
}

.h-65 {
    height: 65% !important;
}

.h-70 {
    height: 70% !important;
}

.h-75 {
    height: 75% !important;
}

.h-80 {
    height: 80% !important;
}

.h-85 {
    height: 85% !important;
}

.h-90 {
    height: 90% !important;
}

.h-95 {
    height: 95% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.hm-80 {
    max-height: 80% !important;
}

.hm-90 {
    max-height: 90% !important;
}

.hm-100 {
    max-height: 100% !important;
}

.hmin-60 {
    min-height: 60% !important;
}
.hmin-100 {
    min-height: 100% !important;
}

.wmin-60 {
    min-width: 60% !important;
}

iframe {
    display: none;
}

.textarea-static {
    resize: none;
}

.tooltip-resizable > .tooltip-inner {
  max-width: 100%;
}

input[type=file]::file-selector-button {
    margin-right: 1rem;
    background-color: $primary;
    color: $primary-inverse;
    display: inline-block;
    outline: none !important;
    border: 1px solid $primary;
    vertical-align: middle;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    font-size: 1rem;
    padding: 0.65rem 1rem;
    border-radius: .42rem;
    cursor: inherit;

    &:hover {
        background-color: $primary-hover;
        border-color: $primary-hover;
    }
}

input[type=file] {
    cursor: pointer;
    &:disabled {
        opacity: .6;
        cursor: url('../icons/Not-Allowed.svg'), not-allowed !important;
    }
}

.remove-input-icon {
    background-image: none !important;
    padding-right: 0 !important;
}

// input[type=file]::file-selector-button {
//     @extend .btn;
//     @extend .btn-primary;
//     margin-right: 1rem;
// }

.remove-button {
    background-color: white !important;
    border: none !important;

    & > .delete-button > svg {
        circle {
            fill: rgba(181, 181, 195, .3) !important
        }
    }

    &:hover {
        background-color: var(--danger) !important;
        & > .delete-button > svg {
            circle {
                fill: #F3F6F9 !important
            }
            
            rect {
                fill: #F64E60 !important
            }
        }
    }
}


.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: .3rem;
}


.react-datepicker-popper {
    // transform: translate(10px, 64px) !important;
    // inset: auto auto 0 0;
}

.article-planogram-tooltip > .tooltip-inner {
    background-color: #212121;
    color: #F7F9FB;
    border: 1px solid #F7F9FB;
    text-align: start;
    font-size: 10px;
    line-height: 13px;
    border-radius: 6px;
    padding: 6px;
}

.nested-tables-container .reset-expansion-style {
    padding: 0;
}

#root-portal {
    z-index: 1000000; //Hack to get date picker to show over modals and not get cliped
}

.hide-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

// Fix datepickers in modals that go outside of it
.react-datepicker-popper {
    // position: fixed !important;
    z-index: 10000000 !important;
}

.react-datepicker__tab-loop {
    position: fixed !important;
    z-index: 10000000 !important;
}

.custom-page-header {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
}

.common-file-icon-text {
    font: bold 15px sans-serif;
    fill: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ProseMirror {
    outline: none !important;
    font-size: 1rem;
    color: #464E5F;
    border: 1px solid #E5EAEE;
    padding: 0.65rem 1rem;
    border-radius: 0.42rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    max-height: 300px;
    overflow-y: auto;
    background-position: top calc(0.375em + 0.325rem) right calc(0.375em + 0.325rem) !important;
    min-height: 3rem;

    &.focused {
        border-color: #6993FF;
    }

    &.success {
        border-color: #1BC5BD;
    }

    & > * {
        margin: 0 !important;
    } 
} 