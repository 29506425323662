//
// Progress
//


.progress {
    &.progress-xs {
        height: $progress-height-xs;

        .progress-bar {
        	border-radius: $border-radius-sm;
        }
    }

    &.progress-sm {
        height: $progress-height-sm;

        .progress-bar {
        	border-radius: $border-radius-sm;
        }
    }

    &.progress-lg {
        height: $progress-height-lg;

       	.progress-bar {
        	border-radius: $border-radius-lg;
        }
    }

    &.warning {
        background-color: #FFDD9B;
    }

    &.primary {
        background-color: #ADD6FF;
    }

    &.danger {
        background-color: rgba(246, 78, 96, .3);
    }

    &.info {
        background-color: #CFB8FE;
    }
}
