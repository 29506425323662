.more-filters-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    cursor: pointer;
    border-radius: 0.42rem;
    height: 2.9rem;
    background-color: #F3F6F9;
    transition: all .2s ease-in;
    position: relative;

    & > .red-dot {
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: red;
        border-radius: 5px;
        border: 1px solid white;
        left: 16px;
        top: 8px;
    }

    & > .more-icon-color {
        &.arrow-icon {
            transition: all .15s ease-in-out;
            &.up {
                transform: rotate(180deg);
            }
        }
    }

    &:hover {
        background-color: #3699FF;

        & > div > .more-icon-color > svg {
            path {
                fill: #F3F6F9 !important
            }
        }

        & > .more-icon-color > svg {
            path {
                fill: #F3F6F9 !important
            }
        }
    }
}

.read-only-btn {
    background-color: rgba(0, 0, 0, 0);
    transition: all .15s ease-in;
    padding: 0.45rem 0.45rem !important;
    border: none !important;

    & > span {
        
    }

    &.btn-light {
        border: none;
        background-color: rgba(0, 0, 0, 0);

        &:hover {
            background-color: #F3F6F9 !important;
            
            & > .more-icon-color > svg {
                path {
                    fill: #3699FF !important
                }
            }
        }

        & > .more-icon-color > svg {
            path {
                fill: #B5B5C3 !important
            }
        }
        
    }

    &.btn-primary {
        border: none;
        background-color: #3699FF;
        & > .more-icon-color > svg {
            path {
                fill: #F3F6F9 !important
            }
        }
    }
}