// Custom styles
@import "./styles/general.scss";
@import "./styles/leaflet.scss";

// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import '~react-checkbox-tree/lib/react-checkbox-tree.css';

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem; 
  code { 
      overflow-y: auto;
      display: block;

      span {
        background: none transparent !important;
      }
  }
}

.json > pre {
  background: none #fff !important;

}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
    th {
        outline: none;

        &.sortable {
            .svg-icon-sort {
                opacity: 0;
            }

            &:hover {
                cursor: pointer;

                .svg-icon-sort {
                    opacity: 1;
                }
            }
        }
    }

    .table.table-head-custom thead tr {
        .sortable-active {
            color: $primary !important;
        }
    }

    .table.table-head-custom {
        th {
            vertical-align: top;
            //display: flex;
        }
    }
}

.react-datepicker-wrapper {
    width: 100%;
}

// Validation
.form-select {
    background-image: none !important;
}

.react-checkbox-tree {
    flex-direction: row !important;
    //font-size: 22px !important;
    .rct-node-icon {
        color: #3699FF;
       
        @include svg-icon-color(#3699FF);
    }
}


    .rct-options {
        text-align: left !important;
    }
.rct-collapse *, .rct-checkbox *, .rct-node-icon * {
    width: 18px;
}

.container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 100% !important;
}

.searchBox {
    border-color: #E5EAEE !important;
}

legend {
    padding-right: 5px !important;
    padding-left: 5px !important;
    font-size: 13px !important;
    width: inherit !important;
}

fieldset {
    border: 1px solid #E5EAEE !important;
    border-radius: 0.42rem !important;
    padding: 5px;
}

.content-backdrop {
    z-index: 10 !important;
    width: 100% !important;
    height: 100% !important;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000000;
}
.content-backdrop.fade {
    opacity: 0;
}
.content-backdrop.show {
    opacity: 0.05;
}

.content-backdrop-loader {
    position: fixed;
    left: calc(100%/2);
    top: calc(90vh/2);
}


.footer {
    z-index: 97;
}

.table-header {
    display: flex;
    width:100%;
    flex-direction:row-reverse;
    padding-bottom: 10px;

    .table-label {
        flex-grow: 1;
    }

}

.grid-modal-dialog {
    max-width: 100% !important;
    padding: 20px;
}

.width-1000{
    width: 1000px;
    min-height: 500px;
}

.width-1500 {
    width: 1500px;
    min-height: 500px;
}

.modal-content-backdrop {
    z-index: 100 !important;
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000000;
    opacity: 0.05;
}

.modal-content-backdrop-loader {
    position: absolute;
    left: calc(100%/2 - 100px);
    top: calc(100%/2);
}

.summary {
    font-size: 13px;
    span {
        font-weight: bold;
        margin-right: 0.4rem;
        margin-left: 0.45rem;
    }
}

.tabs-badge {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    background-color: $gray-500;
    color: white;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.tabs-badge-selected {
    background-color: $primary;
    width: 30px;
    height: 30px;
}

svg|title {
    pointer-events: none;
}