.required-field-marker::after {
  content: ' *';
  color: #F64E60;
}

.required {
  color: #F64E60
}

.cursor-pointer {
  cursor: pointer;
}
