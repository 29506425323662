

.NavigationMenuContainer {
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    align-items: center;

    &::-webkit-scrollbar {
        display: none;
    }

    & > ul {
        height: 100%;
        flex-wrap: nowrap !important;

        & > * {
            white-space: nowrap;
        }
    }
}