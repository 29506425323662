//
// Aside light theme
//


// Initialization of global variables, mixins and functions
@import "../../../init";

// Base
.aside {
	background-color: get($layout-themes, light);
	box-shadow: 0px 0px 28px 0px rgba(82, 63, 105, 0.08);

	// Aside Menu
	.aside-menu {
		// Scrollbar
		@include perfect-scrollbar-theme(#e0e2f0);
	}
}

// Build aside menu theme
@include menu-ver-build-theme($aside-menu-config, light);
