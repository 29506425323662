.article-planogram-tooltip > .tooltip-inner {
    background-color: #212121;
    color: #F7F9FB;
    border: 1px solid #F7F9FB;
    text-align: start;
    font-size: 10px;
    line-height: 13px;
    border-radius: 6px;
    padding: 6px;
}

.article-planogram-item {
    overflow: hidden;
    position: relative;
    user-select: none;
    color: white;
    border: none;
    background-color: transparent;
    box-sizing: content-box;
    align-items: end;
    justify-content: end;


    transition: all .15s ease-in;

    &:hover,
    &.selected {
        overflow: visible;
        outline-style: solid;
        outline-color: #2BFFF7;
        // outline: 1px solid #2BFFF7;
        z-index: 9999;
        // outline-offset: -1px;
    }

    & > .selected-overlay {
        width: 100%;
        height: 100%;
        background-color: #2BFFF7;
        opacity: .3;
        z-index: 3;
        position: absolute;
    }

    & > .product-image {
        top: 0;
        position: absolute;
        transition: width .15s ease-in;
        transition: background-color .15s ease-in;
    }

    &.selected {
        & > input:last-child {
            background-color: #3699FF;
        }
    }

    & > input:last-child {
        left: 50%;
        color: white;
        text-align: center;
        transform-origin: bottom;
        background-color: black;
        transition: background-color .15s ease-in;
        z-index: 99;
        bottom: 0;
        border: 1px solid #F7F9FB;
        width: 25px;
        height: 25px;
        border-radius: .52rem !important;
        outline: none;

        // position: absolute;
        &::selection {
            background: #3699FF;
        }

        &::-moz-selection{
            background: #3699FF;
        }
    }
    // & > .depth-counter:last-child {
    //     left: calc(50% - 12px);
    //     position: absolute;
    //     transform-origin: bottom;
    //     z-index: 200;
    //     bottom: 0;
    //     width: 25px;
    //     height: 25px;

    //     & > input {
    //         z-index: 99;
    //         color: white;
    //         text-align: center;
    //         background-color: black;
    //         transition: background-color .15s ease-in;
    //         border: 1px solid #F7F9FB;
    //         width: 25px;
    //         height: 25px;
    //         outline: none;
    //         border-radius: .52rem !important;

    //         &::selection {
    //           background: #3699FF;
    //         }
    
    //         &::-moz-selection{
    //           background: #3699FF;
    //         }
    //     }
    // }

    &:hover {
        z-index: 300 !important;
        & > .depth-counter:last-child {

            & > input {
                background-color: #187DE4;
                z-index: 200;
    
                &::selection {
                    background: #187DE4;
                }
    
                &::-moz-selection{
                    background: #187DE4;  
                }
            }
            
        }
    }
}

.empty-item {
    outline-style: solid;
}

.article-margin {
    overflow: visible;
    outline: none;
    z-index: 9999;

    & > .margin-preview-element {
        background-color: transparent;
        opacity: .5;
    }

    &.selected {
        outline-style: solid !important;
        outline-color: #ff7499 !important;
    }

    &.selected,
    &:hover {
        & > .margin-preview-element {
            background-color: #ff7499 !important;
            opacity: .5;
        }
    }
}

.side-controls-container {
    &:focus {
        border: none;
        outline: none;
    }

    & > .side-list {
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    
        &:focus {
            border: none;
            outline: none;
        }
    
        & > .article-list-item {
            margin: 6px 0px;
            justify-content: start;
            align-items: center;
            min-height: 72px;
            color: grey;
            background-color: $white;
            cursor: grab;
            border-radius: $border-radius;
            border-width: 1px;
            border-style: solid;
            border: 1px solid #D7E3F0;
            user-select: none;

            &:hover {
                box-shadow: inset 0 0 0 1px #D7E3F0;
            }

            &:focus {
                outline: none;
            }
            
            &.active {
                border: 1px solid #3699FF !important;
                box-shadow: inset 0 0 0 1px #3699FF !important;
                outline: none;
            }
            
            &.info {
                border: 1px solid #8950FC !important;
                box-shadow: inset 0 0 0 1px #8950FC !important;
                outline: none;
            }
    
            & > .article-data {
                justify-content: center;
    
                & > .identifier {
                    color: $text-muted;
                    line-height: 17px;
                }
                
                & > .name {
                    color: $dark-75;
                    line-height: 20px;
                }
            }
        }
    }
}
